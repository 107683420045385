<template>
  <b-row>
    <b-col md="8">
      <b-overlay
        opacity="0.5"
        :show="statistics_overlay"
        spinner-variant="success"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-card no-body class="card-statistics">
          <b-card-header>
            <b-card-title>Estatísticas</b-card-title>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col>
                <b-media no-body style="margin-bottom: 1rem">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-danger">
                      <feather-icon size="24" icon="BoxIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4
                      class="font-weight-bolder mb-0"
                      v-if="number_devices != ''"
                    >
                      {{ number_devices }}
                    </h4>
                    <h4 class="font-weight-bolder mb-0" v-else>0</h4>
                    <b-card-text class="font-small-3 mb-0">
                      Dispositivos
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>

              <b-col>
                <b-media no-body style="margin-bottom: 1rem">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="LayoutIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4
                      class="font-weight-bolder mb-0"
                      v-if="possible_dashboards.length != []"
                    >
                      {{ possible_dashboards.length }}
                    </h4>
                    <h4 class="font-weight-bolder mb-0" v-else>0</h4>
                    <b-card-text class="font-small-3 mb-0">
                      Dashboards
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>

              <b-col>
                <b-media no-body style="margin-bottom: 1rem">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-warning">
                      <feather-icon size="24" icon="UsersIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ $store.state.group_exist.length }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Grupos
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-overlay>
      <b-overlay
        opacity="0.5"
        :show="events_overlay"
        spinner-variant="success"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-card>
          <b-card-title> Eventos </b-card-title>
          <b-table
            responsive="sm"
            :items="items"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template #cell(actions)="data">
              <div>
                <b-button
                  variant="flat-primary"
                  class="btn-icon"
                  @click="$store.state.selected_condition = data.item.condicoId"
                  v-b-tooltip.hover.v-primary
                  title="Detalhes do evento"
                  to="/events"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>

                <b-button
                  v-if="data.item.visto == 0"
                  variant="flat-success"
                  class="btn-icon"
                  @click="check_event(data.item.id)"
                  :disabled="loading"
                  v-b-tooltip.hover.v-success
                  title="Marcar como visto"
                >
                  <feather-icon icon="CheckCircleIcon" />
                </b-button>
              </div>
            </template>

            <template
              #cell(deviceId)="data"
              v-if="possible_devices.length != 0"
            >
              {{
                possible_devices.find(
                  (find_data) => find_data.id == data.item.deviceId
                ).name
              }}
            </template>

            <template
              #cell(message)="data"
              v-if="possible_conditions.length != 0"
            >
              {{
                possible_conditions.find(
                  (find_data) => find_data.id == data.item.condicoId
                ).message
              }}
            </template>

            <template #cell(stats)="data">
              <b-badge :variant="possible_colors[data.item.visto].color">
                {{ possible_colors[data.item.visto].check }}
              </b-badge>
            </template>
          </b-table>

          <b-pagination
            v-model="currentPage"
            :total-rows="items.length"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-card>
      </b-overlay>
    </b-col>

    <b-col md="4">
      <b-overlay
        opacity="0.5"
        :show="timeline_overlay"
        spinner-variant="success"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-card no-body>
          <b-card-header>
            <b-card-title class="ml-25"> User Timeline </b-card-title>
          </b-card-header>

          <!-- timeline -->
          <b-card-body>
            <app-timeline>
              <div
                v-for="item in user_timeline"
                :key="'element_' + item.id"
                style="margin-bottom: 1.5rem"
              >
                <app-timeline-item
                  :title="timeline_data[item.codigo].title"
                  :subtitle="timeline_data[item.codigo].subtitle"
                  :time="new Date(item.createdAt).toLocaleString()"
                  :variant="timeline_data[item.codigo].color"
                />
              </div>
            </app-timeline>
            <!--/ timeline -->
          </b-card-body>
        </b-card>
      </b-overlay>
    </b-col>

    <b-col md="4"> </b-col>
  </b-row>
</template>

<script>
import { BTable } from "bootstrap-vue";
import {
  BCard,
  BCardHeader,
  BOverlay,
  BCardTitle,
  BCardText,
  BCardBody,
  BPagination,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BButton,
  BBadge,
  VBTooltip,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";
import Cookies from "js-cookie";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import api from "../service/api.js";

export default {
  components: {
    BOverlay,
    ToastificationContent,
    VBTooltip,
    BBadge,
    BButton,
    BPagination,
    BTable,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  async created() {
    var self = this;
    if (Cookies.get("token_api") != null) {
      store.state.token_api = Cookies.get("token_api");
    }
    if (Cookies.get("token_api") == undefined) {
      top.location.href = "/login";
    }
    if(store.state.refresh_interval == ''){
      store.state.refresh_interval = setInterval(
      api.refresh_token,
        120000
      );
      store.state.refresh_interval = true;
    }
    self.requests();

    // setInterval(created, 3000);
  },

  data() {
    return {
      statistics_overlay: true,
      timeline_overlay: true,
      events_overlay: true,
      loading: false.value,
      possible_conditions: [],
      possible_devices: [],
      perPage: 6,
      pageOptions: [3, 5, 10],
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      items: [],
      possible_colors: [
        {
          id: 0,
          color: "light-danger",
          check: "Não visto",
        },
        {
          id: 1,
          color: "light-info",
          check: "Visto",
        },
      ],
      fields: [
        { key: "deviceId", label: "Dispositivo", sortable: false },

        { key: "message", label: "Mensagem", sortable: false },

        { key: "stats", label: "Status", sortable: false },

        { key: "actions", label: "Ação", sortable: false },
      ],

      possible_dashboards: [],
      number_devices: [],
      user_timeline: [],

      timeline_data: [
        {
          title: "Boas vindas ao Sync Iot!",
          subtitle: "Qualquer dúvida pode entrar em contato",
          type: "Boas vindas",
          color: "secondary",
        },
        {
          title: "Dispositivo criado",
          subtitle: "Um novo dispositivo foi adicionado ",
          type: "Adicionado",
          color: "success",
        },
        {
          title: "Novo grupo",
          subtitle: "Foi criado um grupo",
          type: "Adicionado",
          color: "success",
        },
        {
          title: "Nova dashboard",
          subtitle: "Foi criado uma nova dashboard",
          type: "Adicionado",
          color: "success",
        },
        {
          title: "Dispositivo removido",
          subtitle: "Algum dispositivo foi removido",
          type: "Removido",
          color: "danger",
        },
        {
          title: "Grupo removido",
          subtitle: "Algum grupo foi removido",
          type: "Removido",
          color: "danger",
        },
        {
          title: "Dispositivo atualizado",
          subtitle: "O dispositivo foi atualizado",
          type: "Atualizado",
          color: "warning",
        },
        {
          title: "Grupo atualizado",
          subtitle: "O grupo foi atualizado",
          type: "Atualizado",
          color: "warning",
        },
      ],
    };
  },
  methods: {
    showToast(variant, title, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    async check_event(event) {
      var self = this;
      self.loading = true;
      const headers = {
        Authorization: this.$store.state.token_api,
      };

      let data = {
        id: this.items.find((find_data) => find_data.id == event).id,
        groupId: this.items.find((find_data) => find_data.id == event).groupId,
        visto: 1,
      };

      await axios
        .patch("https://tekie.cloud:3031/eventos", data, { headers })

        .then(function (response) {
          self.requests();
          self.showToast("success", "Notificação", "BellIcon", "Evento lido!");
        })
        .catch(function (e) {});
    },
    async requests() {
      var self = this;

      await axios
        .get("https://tekie.cloud:3031/dashboards", {
          headers: {
            Authorization: this.$store.state.token_api,
          },
        })

        .then(function (response) {
          self.possible_dashboards = response.data.data;
        })
        .catch(function (error) {
          //e);
        });

      await axios
        .get("https://tekie.cloud:3031/timeline", {
          headers: {
            Authorization: this.$store.state.token_api,
          },
        })

        .then(function (response) {
          var number_infos = 10;
          if (response.data.data.length > number_infos) {
            self.user_timeline = response.data.data
              .splice(response.data.data.length - number_infos, number_infos)
              .reverse();
          } else {
            self.user_timeline = response.data.data;
          }
        })
        .catch(function (error) {
          //error);
          //"Deu erro");
        });
      self.timeline_overlay = false;

      await axios
        .get("https://tekie.cloud:3031/devices", {
          headers: {
            Authorization: this.$store.state.token_api,
          },
        })

        .then(function (response) {
          self.number_devices = response.data.total;
          self.possible_devices = response.data.data;
        })
        .catch(function (error) {
          //error);
          //"Deu erro");
        });

      await axios
        .get(
          "https://tekie.cloud:3031/eventos?idGroup=" +
            store.state.active_group.id,
          {
            headers: {
              Authorization: this.$store.state.token_api,
            },
          }
        )

        .then(function (response) {
          if (response.data.error == undefined) {
            self.items = response.data.eventos.data;
          }
        })
        .catch(function (e) {
          //"Erro ao tentar pegar eventos: ", e);
        });

      self.events_overlay = false;

      await axios
        .get(
          "https://tekie.cloud:3031/condicoes?idGroup=" +
            store.state.active_group.id,
          {
            headers: {
              Authorization: this.$store.state.token_api,
            },
          }
        )

        .then(function (response) {
          if (response.data.error == undefined) {
            self.possible_conditions = response.data.condicoes.data;
          }
        })
        .catch(function (e) {
          //"Erro ao tentar pegar eventos: ", e);
        });
      self.statistics_overlay = false;
      self.loading = false;
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.currentPage = 1;
    },
  },
};
</script>
